import { Item as DropdownMenuItem } from '@radix-ui/react-dropdown-menu'
import type { FC } from 'react'

import { classNames } from '../utils/classNames'

export interface DropdownPopItemProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string
}

export const DropdownPopItem: FC<DropdownPopItemProps> = ({
  className,
  children,
  ...other
}) => {
  return (
    <DropdownMenuItem asChild>
      <button
        className={classNames(
          className ?? '',
          'flex w-full items-center text-start space-x-2.5 rounded-md py-1.5  px-2.5 font-medium  hover:bg-blue-50',
        )}
        type="button"
        {...other}
      >
        {children}
      </button>
    </DropdownMenuItem>
  )
}

export default DropdownPopItem
