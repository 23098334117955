import { CheckCircleIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import { Button } from '@people/ui/buttons'
import { HeaderModal } from '@people/ui/modals'
import { ButtonSpinner } from '@people/ui/spinners'
import { trpc } from '@utils/trpc'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FC } from 'react'

export interface JobFormModalInterface {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  orgSlug: string
}

export const JobFormModal: FC<JobFormModalInterface> = ({
  isOpen,
  setIsOpen,
  orgSlug,
}) => {
  const { t } = useTranslation('common')
  const { push } = useRouter()

  const { data: positions } = trpc.useQuery([
    'ats.position.getByOrgForJobForms',
    {
      orgSlug,
    },
  ])

  const {
    mutate: createJobForm,
    isLoading: isCreatingJobForm,
    variables,
  } = trpc.useMutation('ats.job.createTalentPoolJob', {
    onSuccess: data => {
      push(`/${orgSlug}/${data.locationSlug}/${data.jobSlug}`)
    },
  })

  return (
    <HeaderModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      header={() => (
        <div className="text-left">
          <div className="font-semibold text-slate-900">
            {t('createNewGeneralApplication')}
          </div>
          <div className="text-slate-500">
            {t('chooseJobTypeToGeneralApplication')}
          </div>
        </div>
      )}
    >
      <div className="divide-y divide-slate-200 w-[700px] max-h-[650px] overflow-y-scroll">
        {positions
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map(position => (
            <div
              key={position.id}
              className="text-left px-5 py-3.5 items-center flex gap-3"
            >
              <Link href={`/${orgSlug}/settings/job-type/${position.id}`}>
                <a>
                  <div className="flex items-center gap-1">
                    <ExternalLinkIcon className="w-4 h-4 text-blue-600 flex-shrink-0" />
                    <div className="line-clamp-1 max-w-[500px] text-blue-600">
                      {position.name}
                    </div>
                  </div>
                </a>
              </Link>

              {position.jobs.length > 0 ? (
                <div className="flex items-center gap-2 ml-auto">
                  <CheckCircleIcon className="w-4 h-4 text-teal-500 flex-shrink-0" />
                  <span className="text-teal-500 whitespace-nowrap">
                    {t('generalApplicationExists')}
                  </span>
                </div>
              ) : (
                <Button
                  type="button"
                  variant="primary"
                  size="sm"
                  className="ml-auto"
                  disabled={
                    isCreatingJobForm && variables?.positionId === position.id
                  }
                  onClick={() => {
                    createJobForm({
                      positionId: position.id,
                    })
                  }}
                >
                  {isCreatingJobForm &&
                  variables?.positionId === position.id ? (
                    <ButtonSpinner className="mr-2 h-4 w-4 animate-spin" />
                  ) : null}
                  {t('createGeneralApplication')}
                </Button>
              )}
            </div>
          ))}
      </div>
    </HeaderModal>
  )
}
