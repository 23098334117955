import { trpc } from '@/utils/trpc'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'

import { FormInput } from '@people/ui/form'
import { useNotification } from '@people/ui/hooks/useNotification'
import { ConfirmationModal } from '@people/ui/modals'
import { ModalShellProps } from '@people/ui/modals/ModalShell'

interface DeleteLocationModalProps extends ModalShellProps {
  locationId: string
}

export const DeleteLocationModal: FC<DeleteLocationModalProps> = ({
  isOpen,
  setIsOpen,
  locationId,
}) => {
  const { t } = useTranslation('common')
  const REQUIRED_DELETE_LOCATION_CONFIRMATION_INPUT_VALUE = 'DELETE LOCATION'

  const [deleteConfirmationValue, setDeleteConfirmationValue] =
    useState<string>()

  const { showNotification } = useNotification()

  const utils = trpc.useContext()
  const router = useRouter()
  const deleteLocation = trpc.useMutation('organisationLocation.delete', {
    onSuccess: () => {
      setIsOpen(false)
      utils.invalidateQueries(['organisationLocation.getByOrgSlug'])
      router.replace(`/${router.query.orgSlug}/`)
    },
    onError: error => {
      showNotification({
        type: 'error',
        title: t('errorDeletingLocation'),
        message: error.message,
      })
    },
  })

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={t('deleteLocationModalTitle')}
      setIsOpen={open => setIsOpen(open)}
      isCTADisabled={
        deleteConfirmationValue !==
        REQUIRED_DELETE_LOCATION_CONFIRMATION_INPUT_VALUE
      }
      onConfirm={() => {
        if (
          deleteConfirmationValue !==
          REQUIRED_DELETE_LOCATION_CONFIRMATION_INPUT_VALUE
        ) {
          return
        }

        deleteLocation.mutate({
          locationId: locationId,
        })
      }}
      CTA={t('deleteLocationModalCTA')}
      cancel={t('cancel')}
      type="danger"
    >
      {t('deleteLocationModalSure')}

      <p className="mt-5 text-slate-900">
        {t('deleteLocationModalInstruction')}
      </p>

      <FormInput
        className=""
        name="deleteConfirmationValue"
        clearLabel
        type="text"
        onChange={e => {
          setDeleteConfirmationValue(e.target.value)
        }}
      />

      <div className="mt-5 mb-5 h-[1px] w-full bg-slate-200" />
    </ConfirmationModal>
  )
}
