import { Popover, Transition } from '@headlessui/react'
import { Placement } from '@popperjs/core/lib/enums'
import { FC, Fragment, ReactNode, useState } from 'react'
import { usePopper } from 'react-popper'

import { classNames } from '../utils'

export interface DropdownProps {
  button: ReactNode
  items: ReactNode
  buttonClassName?: string
  className?: string
  itemsClassName?: string
  placement?: Placement
}

export const Dropdown: FC<DropdownProps> = ({
  button,
  items,
  className,
  buttonClassName,
  itemsClassName,
}) => {
  let [referenceElement, setReferenceElement] = useState<HTMLElement>()
  let [popperElement, setPopperElement] = useState<HTMLElement>()
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  })

  return (
    <Popover as="div" className={classNames('relative', className || '')}>
      <Popover.Button
        /* @ts-ignore */
        ref={setReferenceElement}
        className={classNames(
          'inline-flex items-center',
          buttonClassName ?? '',
        )}
      >
        {button}
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel
          /* @ts-ignore */
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={classNames(
            'absolute top-12 z-50 text-slate-900',
            itemsClassName || '',
          )}
        >
          {items}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default Dropdown
