import { trpc } from '@/utils/trpc'
import { useForm } from '@mantine/form'
import type { OrganisationLocation } from '@prisma/client'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'

import { Button } from '@people/ui/buttons'
import { useNotification } from '@people/ui/hooks/useNotification'
import { HeaderModal } from '@people/ui/modals'
import { ModalShellProps } from '@people/ui/modals/ModalShell'
import { ButtonSpinner } from '@people/ui/spinners'

import { GooglePlaceField } from '@people/ui/locations'
import {
  LocationAddressForm,
  LocationAddressFormType,
} from '../settings/location/LocationAddressForm'

interface NewLocationModalProps extends ModalShellProps {
  orgSlug: string
  newLocationCallback: (location: OrganisationLocation) => void
}

export const NewLocationModal: FC<NewLocationModalProps> = ({
  isOpen,
  setIsOpen,
  orgSlug,
  newLocationCallback,
}) => {
  const { t } = useTranslation('common')

  const { showNotification } = useNotification()

  const form = useForm<LocationAddressFormType>({
    initialValues: {
      locations: [
        {
          id: '',
          streetAddress: '',
          city: '',
          postalCode: '',
          countryCode: '',
          latitude: 0,
          longitude: 0,
          name: '',
        },
      ],
    },
  })

  const createLocation = trpc.useMutation('organisationLocation.create', {
    onSuccess: createdLocation => {
      newLocationCallback(createdLocation)
    },
    onError: err => {
      showNotification({
        type: 'error',
        title: t('error'),
        message: err.message,
      })
    },
  })

  const handleLocationSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    createLocation.mutate({
      orgSlug: orgSlug,
      location: {
        name: form.values.locations[0].name,
        streetAddress: form.values.locations[0].streetAddress,
        city: form.values.locations[0].city,
        postalCode: form.values.locations[0].postalCode,
        countryCode: form.values.locations[0].countryCode,
        latitude: form.values.locations[0].latitude,
        longitude: form.values.locations[0].longitude,
      },
    })
  }

  const allFieldsValid =
    form.values.locations[0].streetAddress !== '' &&
    form.values.locations[0].city !== '' &&
    form.values.locations[0].postalCode !== '' &&
    form.values.locations[0].countryCode !== '' &&
    form.values.locations[0].latitude !== -1 &&
    form.values.locations[0].longitude !== -1

  const [place, setPlace] = useState<google.maps.places.PlaceResult>()
  useEffect(
    function setFormValues() {
      if (place) {
        form.setFieldValue(`locations.0.name`, place?.name ?? '')
        form.setFieldValue(`locations.0.streetAddress`, place?.name ?? '')
        form.setFieldValue(
          `locations.0.postalCode`,
          place?.address_components?.find(c => c.types[0] === 'postal_code')
            ?.long_name ?? '',
        )
        form.setFieldValue(
          `locations.0.city`,
          place?.address_components?.find(c => c.types[0] === 'locality')
            ?.long_name ?? '',
        )
        form.setFieldValue(
          `locations.0.countryCode`,
          place?.address_components?.find(c => c.types[0] === 'country')
            ?.short_name ?? '',
        )
        form.setFieldValue(
          `locations.0.latitude`,
          place?.geometry?.location?.lat() ?? 0,
        )
        form.setFieldValue(
          `locations.0.longitude`,
          place?.geometry?.location?.lng() ?? 0,
        )
      }
    },
    [place],
  )

  return (
    <HeaderModal
      header={t('newLocationModalTitle')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="!max-w-[480px] !p-0"
      onInteractOutside={e => {
        if (
          // @ts-ignore
          e.target?.classList.contains('pac-item-query') ||
          // @ts-ignore
          e.target?.classList.contains('pac-item') ||
          // @ts-ignore
          e.target?.classList.contains('pac-icon') ||
          // @ts-ignore
          e.target?.parentElement?.classList.contains('pac-item-query') ||
          // @ts-ignore
          e.target?.parentElement?.classList.contains('pac-item')
        ) {
          e.preventDefault()
        } else {
          setIsOpen(false)
        }
      }}
    >
      <form className="text-left">
        <div className="px-6 py-6">
          <GooglePlaceField setPlace={setPlace} />
        </div>
        {form.values.locations[0].city !== '' && (
          <LocationAddressForm
            disabled={false}
            form={form}
            idx={0}
            clearLabels
            className="!pt-0"
            isIniatialDataLoading={false}
            isPopup={true}
          />
        )}
        <div className="flex gap-[14px] border-t border-slate-200 p-5">
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpen(false)
            }}
            className="flex-1"
            type="button"
          >
            {t('cancel')}
          </Button>

          <Button
            className="flex-1 disabled:text-slate-300"
            disabled={!allFieldsValid}
            variant={allFieldsValid ? 'primary' : 'disabled'}
            type="submit"
            onClick={event => handleLocationSubmit(event)}
          >
            {createLocation.isLoading && (
              <ButtonSpinner className="-ml-0.5 mr-2 h-5 w-6 animate-spin " />
            )}
            {t('create')}
          </Button>
        </div>
      </form>
    </HeaderModal>
  )
}
