import { FC } from 'react'

import { classNames } from '../utils/classNames'

export interface LocationListItemProps {
  className?: string
  name: string
  jobCount: number
  active?: boolean
}

export const LocationListItem: FC<LocationListItemProps> = ({
  className,
  name,
  jobCount,
  active,
}) => {
  return (
    <div
      className={classNames(
        'rounded py-1.5 hover:bg-blue-50 hover:font-medium ',
        active ? 'bg-blue-50' : '',
        className ?? '',
      )}
    >
      <div className="flex flex-row gap-3 overflow-hidden">
        <div
          className={classNames(
            className ?? '',
            'flex-1 lg:max-w-[180px] truncate overflow-hidden',
            active ? 'font-medium text-blue-600' : 'text-slate-900 ',
          )}
        >
          {name}
        </div>
        <div
          className={classNames(
            'text-sm flex',
            active ? 'font-medium text-blue-600' : 'text-slate-400 ',
            className || '',
          )}
        >
          {jobCount}
        </div>
      </div>
    </div>
  )
}

export default LocationListItem
