import { CANDIDATE_APP_URL } from '@/config/environment'
import { useMemberRole } from '@/hooks/useMemberRole'
import { InferQueryOutput, trpc } from '@/utils/trpc'
import {
  DocumentDuplicateIcon,
  ExternalLinkIcon,
  EyeIcon,
  EyeOffIcon,
  GlobeAltIcon,
  LinkIcon,
  PauseIcon,
  PencilAltIcon,
  PlayIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import { toDisplayJobStatus } from '@/utils/toDisplayJobStatus'
import { hasUserObjectAccess } from '@people/common/utils/permissions'
import { Card } from '@people/ui/cards'
import { DropdownPopItem } from '@people/ui/dropdown'
import { useNotification } from '@people/ui/hooks/useNotification'

import { useTranslation } from 'next-i18next'

export interface JobTableDropdownProps {
  job: InferQueryOutput<'ats.job.getByLocation'>['jobs'][0] | undefined
}

export const JobTableDropdown: FC<JobTableDropdownProps> = ({ job }) => {
  const { t } = useTranslation('common')
  const { query, replace } = useRouter()
  const utils = trpc.useContext()
  const orgSlug = query.orgSlug as string

  const { memberRole } = useMemberRole(orgSlug)

  const { data: userInfo } = trpc.useQuery(['user.getUserInfoAndOrgs'], {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const hasCreateAccess =
    memberRole &&
    hasUserObjectAccess(memberRole, {
      type: 'AtsJob',
      action: 'create',
    })

  const hasUpdateAccess =
    memberRole &&
    hasUserObjectAccess(memberRole, {
      type: 'AtsJob',
      action: 'update',
    })

  const hasDeleteAccess =
    memberRole &&
    hasUserObjectAccess(memberRole, {
      type: 'AtsJob',
      action: 'delete',
    })

  const { showNotification } = useNotification()
  const { mutate: changeJobStatus } = trpc.useMutation('ats.job.status', {
    onSuccess: job => {
      showNotification({
        title: t('jobStatusChanged'),
        message: t('theJobIsNow') + toDisplayJobStatus(job.status, t),
        type: 'success',
      })
      Promise.all([
        utils.invalidateQueries(['ats.job.getByLocation']),
        utils.invalidateQueries(['organisationLocation.getByOrgSlug']),
      ])
    },
    onError: err => {
      showNotification({
        type: 'error',
        title: t('error'),
        message: err.message,
      })
    },
  })

  // This is a hack to prevent the dropdown items to intercept
  // the click event on the DropdownPop Trigger button
  const [showLinks, setShowLinks] = useState(false)
  useEffect(() => {
    const timeoutShow = setTimeout(() => {
      setShowLinks(true)
    }, 300)

    return () => {
      clearTimeout(timeoutShow)
    }
  }, [])

  if (!showLinks) {
    return <></>
  }

  return (
    <Card className="w-[220px] -mt-6 space-y-1 p-1 shadow-md text-slate-900">
      {!job?.organisationLocation.isTalentPool ? (
        <>
          <Link
            href={`/${query.orgSlug}/${job?.organisationLocation.lousyLocationSlug}/${job?.lousySlug}/?job_preview=true`}
          >
            <a>
              <DropdownPopItem tabIndex={-1}>
                <EyeIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
                {t('previewJob')}
              </DropdownPopItem>
            </a>
          </Link>
          <a
            className="flex flex-1"
            href={`${CANDIDATE_APP_URL}/jobs/${query.orgSlug}/${job?.organisationLocation.lousyLocationSlug}/${job?.lousySlug}`}
            target="_blank"
          >
            <DropdownPopItem
              className="flex w-full flex-1 !justify-start"
              tabIndex={-1}
            >
              <GlobeAltIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
              {t('goToJobPage')}
              <div className="flex h-full flex-1 items-center justify-end self-end text-blue-600">
                <ExternalLinkIcon className="h-[18px] w-[18px] " />
              </div>
            </DropdownPopItem>
          </a>
        </>
      ) : null}
      {hasUpdateAccess ? (
        <Link
          href={`/${query.orgSlug}/${job?.organisationLocation.lousyLocationSlug}/${job?.lousySlug}/edit/about`}
        >
          <a className="flex flex-1">
            <DropdownPopItem tabIndex={-1}>
              <PencilAltIcon className="mr-2.5 h-[18px] w-[18px] text-blue-600" />
              {t('edit')}
            </DropdownPopItem>
          </a>
        </Link>
      ) : null}
      {hasCreateAccess && !job?.organisationLocation.isTalentPool ? (
        <DropdownPopItem
          className="flex w-full flex-1 !justify-start"
          tabIndex={-1}
          onClick={() => {
            if (job) {
              replace({
                query: {
                  ...query,
                  duplicateJobId: job.id,
                },
              })
            }
          }}
        >
          <DocumentDuplicateIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
          {t('duplicate')}
        </DropdownPopItem>
      ) : null}

      {(job?.status === 'LIVE' || job?.status === 'CLOSED') &&
        !job.organisationLocation.isTalentPool && (
          <>
            <div className="border-b border-slate-200" />
            <DropdownPopItem
              className="flex w-full !justify-start "
              onClick={e => {
                e.stopPropagation()
                if (job?.id) {
                  navigator.clipboard.writeText(
                    `${CANDIDATE_APP_URL}/jobs/${query.orgSlug}/${job?.organisationLocation.lousyLocationSlug}/${job.lousySlug}`,
                  )
                  showNotification({
                    title: t('linkCopied'),
                    message: t('jobLinkCopiedMessage'),
                    type: 'success',
                  })
                }
              }}
            >
              <LinkIcon className="mr-3 h-[18px] w-[18px] text-blue-600 " />
              {t('copyLinkJob')}
            </DropdownPopItem>
          </>
        )}
      {hasUpdateAccess ? <div className="border-b border-slate-200" /> : null}
      {userInfo?.user?.isSystemAdmin &&
        (job?.status === 'DRAFT' ||
        job?.status === 'CLOSED' ||
        job?.status === 'LIVE' ? (
          <DropdownPopItem
            className="flex w-full !justify-start"
            onClick={e => {
              e.stopPropagation()
              changeJobStatus({
                status: 'INVISIBLE',
                jobId: job?.id || '',
              })
            }}
          >
            <EyeOffIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
            {job?.status === 'LIVE' ? t('makeInvisible') : t('launchInvisibly')}
          </DropdownPopItem>
        ) : null)}
      {hasUpdateAccess &&
      (job?.status === 'LIVE' || job?.status === 'INVISIBLE') ? (
        <DropdownPopItem
          className="flex w-full !justify-start"
          onClick={e => {
            e.stopPropagation()
            changeJobStatus({
              status: 'CLOSED',
              jobId: job?.id || '',
            })
          }}
        >
          <PauseIcon className="mr-3 h-[18px] w-[18px] text-yellow-500" />
          {t('deactivate')}
        </DropdownPopItem>
      ) : null}
      {hasUpdateAccess &&
      (job?.status === 'CLOSED' ||
        job?.status === 'DRAFT' ||
        job?.status === 'INVISIBLE') ? (
        <DropdownPopItem
          className="flex w-full !justify-start"
          onClick={e => {
            e.stopPropagation()
            changeJobStatus({
              status: 'LIVE',
              jobId: job?.id || '',
            })
          }}
        >
          <PlayIcon className="mr-3 h-[18px] w-[18px] text-emerald-600" />
          {t('activate')}
        </DropdownPopItem>
      ) : null}
      {hasDeleteAccess ? (
        <>
          <div className="border-b border-slate-200" />
          <DropdownPopItem
            className="flex w-full!justify-start !text-red-600"
            onClick={e => {
              e.stopPropagation()
              changeJobStatus({
                status: 'ARCHIVED',
                jobId: job?.id || '',
              })
            }}
          >
            <TrashIcon className="mr-3 h-[18px] w-[18px] " />
            {t('delete')}
          </DropdownPopItem>
        </>
      ) : null}
    </Card>
  )
}
