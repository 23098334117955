import { OrganisationRole } from '@prisma/client'
import { FC, Fragment } from 'react'

import { ButtonSpinner } from '@people/ui/spinners'

import { LocationMember } from './LocationMember'

interface LocationMemberSearchResultProps {
  isLoading: boolean
  members:
    | {
        name?: string | null
        email: string
        image?: string | null
        role: OrganisationRole
      }[]
    | undefined
  onSelectMember: (member: { name: string; email: string }) => void
}

export const LocationMemberSearchResult: FC<
  LocationMemberSearchResultProps
> = ({ isLoading, members, onSelectMember }) => {
  return (
    <Fragment>
      {!isLoading && members && members.length > 0 ? (
        <div className="max-h-[240px] overflow-auto z-50 border-x border-b rounded absolute bg-white top-[70px] w-full">
          {members?.map((m, idx) => {
            return (
              <LocationMember
                key={idx}
                name={m.name as string}
                email={m.email}
                image={m.image as string}
                role={m.role}
                onSelect={() => {
                  onSelectMember({
                    name: m.name as string,
                    email: m.email as string,
                  })
                }}
              />
            )
          })}
        </div>
      ) : (
        <div className="w-full  text-xs text-center absolute bg-white top-[73px]">
          {isLoading ? (
            <ButtonSpinner className="m-auto h-4 w-4 animate-spin" />
          ) : (
            'No members found'
          )}
        </div>
      )}
    </Fragment>
  )
}
