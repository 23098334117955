import type { FC } from 'react'

import IconProps from './IconProps'

export const LockTwoIcon: FC<IconProps> = ({ className, ...other }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M24 0C16.824 0.0088125 11.0088 5.82394 10.9999 13V21C10.9999 21.5523 11.4477 22 12 22H16C16.5523 22 17 21.5523 17 21V13C16.9999 9.13397 20.134 6 24 6C27.8659 6 31 9.13397 31 13V21C31 21.5523 31.4478 22 32 22H36C36.5522 22 37 21.5523 37 21V13C36.9912 5.82394 31.176 0.0088125 24 0Z"
        fill="#2DD4BF"
      />
      <path
        d="M12 20H36C38.7614 20 41 22.2386 41 25V43C41 45.7615 38.7614 48 36 48H12C9.23862 48 7.00006 45.7615 7.00006 43.0001V25.0001C7.00006 22.2386 9.23862 20 12 20Z"
        fill="#2563EB"
      />
      <path
        d="M29 31.0001C29.0116 28.2387 26.7825 25.9907 24.0211 25.979C21.2597 25.9674 19.0117 28.1965 19 30.9579C18.992 32.8667 20.0713 34.6134 21.782 35.4601L21.01 40.8601C20.9326 41.4069 21.3132 41.9129 21.8601 41.9902C21.9064 41.9968 21.9532 42.0001 22 42.0001H26C26.5523 42.0057 27.0045 41.5625 27.01 41.0103C27.0105 40.9594 27.0072 40.9085 26.9999 40.8581L26.2279 35.4581C27.9176 34.6125 28.989 32.8895 29 31.0001Z"
        fill="white"
      />
    </svg>
  )
}

export default LockTwoIcon
