import { FC } from 'react'

import { Card } from '@people/ui/cards'
import { LockTwoIcon } from '@people/ui/icons'

interface AccessDeniedProps {
  title: string
  description: string
}

export const AccessDenied: FC<AccessDeniedProps> = ({ title, description }) => {
  return (
    <div className="h-full w-full items-center">
      <Card className="mx-auto flex flex-col items-center grow mt-32 pt-10 md:w-[616px] max-w-[616px] px-4">
        <LockTwoIcon />
        <p className="md:w-[229px] mt-5 text-slate-900 font-semibold">
          {title}
        </p>

        <p className="md:w-[350px] mt-2.5 text-slate-600 text-center">
          {description}
        </p>

        <p className="text-slate-500 mt-10 pb-10 text-center md:text-left md:w-[416px]">
          Need help? Get in touch with us at{' '}
          <a
            className="underline text-blue-600"
            target="_blank"
            href="mailto:support@teero.com"
          >
            support@teero.com
          </a>
        </p>
      </Card>
    </div>
  )
}
