import xss from 'xss'

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str
  return str[0].toLocaleUpperCase() + str.slice(1)
}

export const trimAll = (val: string): string => {
  /** Handles the cases when there are multiple white spaces in string */
  /** from: 'too much whitespace     here   right?' */
  /** to: 'too much whitespace here right?' */

  return val.replace(/\s+/g, ' ').replace(/^\s+|\s+$/, '')
}

export const sanitize = (str?: string) => {
  if (str === undefined) return undefined
  return xss(str, {
    stripIgnoreTag: true,
  })
}
