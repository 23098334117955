import { useAuth } from '@/hooks/useAuth'
import { InferQueryOutput } from '@/utils/trpc'
import { useState } from 'react'

import { capitalizeFirstLetter } from '@people/common/utils/string'
import { Card } from '@people/ui/cards'
import { DropdownPop, DropdownPopItem } from '@people/ui/dropdown'
import { Tooltip } from '@people/ui/tooltips'
import { classNames } from '@people/ui/utils'

import { useTranslation } from 'next-i18next'
import { LocationMember } from './LocationMember'

interface LocationMemberPreviewProps {
  locationMembers: InferQueryOutput<'org.member.getMembersByLocation'>
  onShare: () => void
}

export const LocationMemberPreview: React.FC<LocationMemberPreviewProps> = ({
  locationMembers,
  onShare,
}) => {
  const { user } = useAuth()
  const { t } = useTranslation('common')

  const [isShowingMembers, setIsShowingMembers] = useState<boolean>(false)

  return (
    <div className="flex flex-row items-center gap-2 sm:border-r pr-4 my-auto">
      <p
        className="text-blue-600 hover:bg-blue-50 p-1 rounded cursor-pointer font-medium text-sm"
        onClick={() => {
          onShare()
        }}
      >
        Add
      </p>
      <div className="flex flex-row">
        {locationMembers?.slice(0, 3).map((m, idx) => {
          return (
            <Tooltip
              key={idx}
              trigger={
                <button
                  className={classNames(
                    ' h-6 w-6 rounded-full bg-slate-50 border overflow-hidden items-center flex justify-center relative',
                    m.image ? 'border-white' : 'border-slate-300',
                    `z-${idx}`,
                    idx > 0 ? '-ml-2' : '',
                  )}
                >
                  {m.image ? (
                    <img src={m.image} alt="" className="inline" />
                  ) : (
                    <div className="cursor-default text-xs font-semibold text-slate-600">
                      {m.name?.[0]}
                    </div>
                  )}
                </button>
              }
            >
              <p>
                {m.name}{' '}
                {user?.email === m.email ? (
                  <span className="text-sm text-slate-400">({t('you')})</span>
                ) : null}
              </p>
              <p className="text-sm text-slate-400">
                {capitalizeFirstLetter(m.role.toLowerCase())}
              </p>
            </Tooltip>
          )
        })}
        {locationMembers && locationMembers.length > 3 ? (
          <DropdownPop
            button={
              <p
                className={classNames(
                  '-ml-2 pt-[3px] bg-slate-50 border-slate-300 h-6 w-6 font-semibold border rounded-full text-xs text-center cursor-pointer relative',
                  isShowingMembers
                    ? 'text-blue-600 border-blue-600'
                    : 'text-slate-600 border-slate-300',
                )}
              >
                +{locationMembers.length - 3}
              </p>
            }
            items={
              <Card className="p-1 mr-28 max-w-[400px] max-h-[400px] overflow-auto mt-1 drop-shadow-md">
                {locationMembers.map((m, idx) => {
                  return (
                    <DropdownPopItem
                      key={idx}
                      className={classNames('text-left w-full')}
                    >
                      <LocationMember
                        name={m.name as string}
                        email={m.email}
                        image={m.image as string}
                        role={m.role}
                      />
                    </DropdownPopItem>
                  )
                })}
              </Card>
            }
          />
        ) : null}
      </div>
    </div>
  )
}
