import { CANDIDATE_APP_URL } from '@/config/environment'
import { useMemberRole } from '@/hooks/useMemberRole'
import { trpc } from '@/utils/trpc'
import { Popover } from '@headlessui/react'
import {
  CogIcon,
  DotsHorizontalIcon,
  ExternalLinkIcon,
  GlobeAltIcon,
  LocationMarkerIcon,
  MapIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, Fragment, useMemo, useState } from 'react'

import {
  hasUserObjectAccess,
  isUserRoleAuthorized,
} from '@people/common/utils/permissions'
import { Button } from '@people/ui/buttons'
import { Card } from '@people/ui/cards'
import { Dropdown, DropdownItem } from '@people/ui/dropdown'
import { IconProps, PostBoxIcon } from '@people/ui/icons'
import { classNames } from '@people/ui/utils'

import EmbedButton from '../iframe/EmbedButton'
import { JobFormModal } from '../job/JobFormModal'
import { LocationMemberModal } from './LocationMemberModal'
import { LocationMemberPreview } from './LocationMemberPreview'

export interface LocationHeaderProps {
  className?: string
  name: string
  streetAddress?: string
  city?: string | null
  postalCode?: string | null
  country?: string | null | undefined
  translations: (key: string) => string
  selectedLocationSlug: string | undefined
  setIsEditLocationModalOpen: (isOpen: boolean) => void
  setIsDeleteLocationModalOpen: (isOpen: boolean) => void
}

export const LocationHeader: FC<LocationHeaderProps> = ({
  className,
  name,
  streetAddress,
  city,
  postalCode,
  country,
  selectedLocationSlug,
  setIsEditLocationModalOpen,
  setIsDeleteLocationModalOpen,
  translations: t,
}) => {
  const { query } = useRouter()
  const orgSlug = query.orgSlug as string

  const [showLocationMembers, setShowLocationMembers] = useState<boolean>(false)
  const [isNewJobFormModalOpen, setIsNewJobFormModalOpen] =
    useState<boolean>(false)
  const { memberRole } = useMemberRole(orgSlug)

  const viewAll = selectedLocationSlug === 'all-jobs'
  const viewGeneralApplications =
    selectedLocationSlug === 'general-applications'

  const { data: locationMembers } = trpc.useQuery(
    [
      'org.member.getMembersByLocation',
      {
        orgSlug,
        locationSlug: selectedLocationSlug || '',
      },
    ],
    {
      enabled: Boolean(orgSlug && selectedLocationSlug && !viewAll),
    },
  )

  const row = useMemo((): {
    value?: string | null
    icon: ((props: React.ComponentProps<'svg'>) => JSX.Element) | FC<IconProps>
  }[] => {
    return [
      {
        value: `${city}, ${country}`,
        icon: LocationMarkerIcon,
      },
      {
        value: `${streetAddress}`,
        icon: MapIcon,
      },
      {
        value: postalCode,
        icon: PostBoxIcon,
      },
    ]
  }, [city, country, streetAddress, postalCode])

  const noLocationDetails = viewAll || viewGeneralApplications

  return (
    <>
      <JobFormModal
        isOpen={isNewJobFormModalOpen}
        setIsOpen={setIsNewJobFormModalOpen}
        orgSlug={orgSlug}
      />
      <Card className={className}>
        <div className="flex flex-col flex-wrap gap-4 px-4 py-4 sm:flex-row">
          <div
            className={classNames(
              'flex flex-1 flex-col flex-wrap',
              noLocationDetails ? 'justify-center' : '',
            )}
          >
            <h2
              className={classNames(
                ' font-semibold text-slate-900',
                noLocationDetails ? 'self-start' : 'mb-2.5',
              )}
            >
              {name}
            </h2>
            <div className="flex flex-col flex-wrap gap-2.5 sm:flex-row sm:items-center">
              {!noLocationDetails &&
                row.map((item, itemIndex) => (
                  <Fragment key={itemIndex}>
                    <span className=" flex flex-row items-center">
                      <item.icon className="h-4 w-4 text-slate-400" />
                      <span className="ml-1 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap text-sm text-slate-900">
                        {item.value}
                      </span>
                    </span>
                    {itemIndex + 1 < row.length && (
                      <span className="-mt-2.5 hidden text-slate-400 md:block">
                        .
                      </span>
                    )}
                  </Fragment>
                ))}
            </div>
          </div>
          {!viewAll && showLocationMembers && memberRole ? (
            <LocationMemberModal
              isOpen={showLocationMembers}
              setIsOpen={open => {
                setShowLocationMembers(open)
              }}
              locationMembers={locationMembers || []}
            />
          ) : null}

          {!viewAll ? (
            <LocationMemberPreview
              locationMembers={locationMembers || []}
              onShare={() => {
                setShowLocationMembers(true)
              }}
            />
          ) : null}
          <div className="flex flex-row sm:w-auto gap-3 sm:self-center text-end">
            {!noLocationDetails ? (
              <Dropdown
                button={
                  <Button tabIndex={-1} variant="secondary" className="h-10">
                    <DotsHorizontalIcon
                      className="h-5 w-5 text-blue-600"
                      aria-hidden="true"
                    />
                  </Button>
                }
                items={
                  <Card className="w-[240px] space-y-1.5 p-1 shadow-md">
                    <Popover.Button
                      as="a"
                      className="flex"
                      href={`${CANDIDATE_APP_URL}/jobs/${query.orgSlug}/${selectedLocationSlug}`}
                      target="_blank"
                    >
                      <DropdownItem>
                        <GlobeAltIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
                        {t('goToLocationPage')}
                        <div className="flex h-full flex-1 items-center justify-end self-end text-blue-600">
                          <ExternalLinkIcon className="h-[18px] w-[18px] " />
                        </div>
                      </DropdownItem>
                    </Popover.Button>

                    {memberRole &&
                    isUserRoleAuthorized({
                      memberRole,
                      minRequiredRole: 'MANAGER',
                    }) ? (
                      <Popover.Button
                        as={EmbedButton}
                        type="location"
                        url={`${query.orgSlug}/${selectedLocationSlug}`}
                      />
                    ) : null}

                    {memberRole &&
                    hasUserObjectAccess(memberRole, {
                      type: 'OrganisationLocation',
                      action: 'update',
                    }) ? (
                      <Popover.Button
                        as={DropdownItem}
                        onClick={() => setIsEditLocationModalOpen(true)}
                      >
                        <CogIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
                        {t('editLocationDetails')}
                      </Popover.Button>
                    ) : null}

                    {memberRole &&
                    hasUserObjectAccess(memberRole, {
                      type: 'OrganisationLocation',
                      action: 'delete',
                    }) ? (
                      <Fragment>
                        <div className="border-b border-slate-200" />
                        <Popover.Button
                          as={DropdownItem}
                          onClick={() => setIsDeleteLocationModalOpen(true)}
                        >
                          <TrashIcon className="mr-3 h-[18px] w-[18px] text-red-600" />
                          {t('deleteLocation')}
                        </Popover.Button>
                      </Fragment>
                    ) : null}
                  </Card>
                }
              />
            ) : (
              <Dropdown
                button={
                  <Button tabIndex={-1} variant="secondary" className="h-10">
                    <DotsHorizontalIcon
                      className="h-5 w-5 text-blue-600"
                      aria-hidden="true"
                    />
                  </Button>
                }
                items={
                  <Card className="w-[240px] space-y-1.5 p-1 shadow-md">
                    <Popover.Button
                      as="a"
                      className="flex"
                      href={`${CANDIDATE_APP_URL}/jobs/${query.orgSlug}`}
                      target="_blank"
                    >
                      <DropdownItem>
                        <GlobeAltIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
                        {t('goToLocationPage')}
                        <div className="flex h-full flex-1 items-center justify-end self-end text-blue-600">
                          <ExternalLinkIcon className="h-[18px] w-[18px] " />
                        </div>
                      </DropdownItem>
                    </Popover.Button>
                  </Card>
                }
              />
            )}

            {memberRole &&
            hasUserObjectAccess(memberRole, {
              type: 'AtsJob',
              action: 'create',
            }) &&
            !viewGeneralApplications ? (
              <Link
                href={`/${query.orgSlug}/${selectedLocationSlug}/job/create`}
              >
                <Button variant="primary" tabIndex={-1}>
                  <PlusCircleIcon
                    className="mr-1.5 h-5 w-5 "
                    aria-hidden="true"
                  />
                  {t('newJob')}
                </Button>
              </Link>
            ) : null}
            {memberRole &&
            hasUserObjectAccess(memberRole, {
              type: 'AtsPosition',
              action: 'create',
            }) &&
            viewGeneralApplications ? (
              <Button
                variant="primary"
                tabIndex={-1}
                onClick={() => {
                  setIsNewJobFormModalOpen(true)
                }}
              >
                <PlusCircleIcon
                  className="mr-1.5 h-5 w-5 "
                  aria-hidden="true"
                />
                {t('new')}
              </Button>
            ) : null}
          </div>
        </div>
      </Card>
    </>
  )
}

export default LocationHeader
