import { useMemberRole } from '@/hooks/useMemberRole'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import type { FC } from 'react'

import { TrashIcon } from '@heroicons/react/outline'
import { hasUserObjectAccess } from '@people/common/utils/permissions'
import { Card } from '@people/ui/cards'
import { DropdownItem } from '@people/ui/dropdown'
import TableDropdown from '@people/ui/dropdown/TableDropdown'
import { useNotification } from '@people/ui/hooks/useNotification'
import { RedoIcon } from '@people/ui/icons'
import { trpc } from '@utils/trpc'

export interface JobTableDropdownProps {
  jobId: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  top: number
  left: number
  onRestoreJob: (jobId: string) => void
}

export const DeletedJobTableDropdown: FC<JobTableDropdownProps> = ({
  jobId,
  isOpen,
  setIsOpen,
  top,
  left,
  onRestoreJob,
}) => {
  const { query } = useRouter()
  const orgSlug = query.orgSlug as string
  const { t } = useTranslation()

  const trpcContext = trpc.useContext()
  const { memberRole } = useMemberRole(orgSlug)

  const hasCreateAccess =
    memberRole &&
    hasUserObjectAccess(memberRole, {
      type: 'AtsJob',
      action: 'create',
    })

  const { showNotification } = useNotification()

  const { data: userInfo } = trpc.useQuery(['user.getUserInfoAndOrgs'], {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const { mutate: deleteJobMutation } = trpc.useMutation('ats.job.delete', {
    onSuccess: () => {
      showNotification({
        type: 'success',
        title: 'Job deleted',
        message: 'Job has been deleted successfully',
      })
      trpcContext.invalidateQueries(['ats.job.getByLocation'])
      setIsOpen(false)
    },
  })

  return (
    <>
      <TableDropdown
        items={
          <Card className="w-[220px] -mt-6 space-y-1 p-1 shadow-md text-slate-900">
            {hasCreateAccess ? (
              <DropdownItem
                className="flex w-full flex-1 !justify-start"
                tabIndex={-1}
                onClick={() => {
                  if (jobId) {
                    onRestoreJob(jobId)
                  }
                  setIsOpen(false)
                }}
              >
                <RedoIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
                {t('restoreJob')}
              </DropdownItem>
            ) : null}
            {userInfo?.user?.isSystemAdmin && (
              <DropdownItem
                className="flex w-full flex-1 !justify-start"
                tabIndex={-1}
                onClick={() => {
                  if (jobId) {
                    if (
                      window.confirm('Do you really want to delete this job?')
                    ) {
                      deleteJobMutation({
                        jobId,
                      })
                    }
                  }
                  setIsOpen(false)
                }}
              >
                <TrashIcon className="mr-3 h-[18px] w-[18px] text-red-600 " />
                {t('deleteJob')}
              </DropdownItem>
            )}
          </Card>
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        top={top}
        left={left}
      />
    </>
  )
}
