import { DeletedJobsTable } from '@/components/job/DeletedJobsTable'
import { InferQueryOutput } from '@/utils/trpc'
import { FC, Fragment, useState } from 'react'

import { useTableDropdown } from '@people/ui/dropdown/TableDropdown'

import { DeletedJobTableDropdown } from './DeletedJobTableDropdown'
import { RestoreJobModal } from './RestoreJobModal'

interface DeletedJobsProps {
  jobs: InferQueryOutput<'ats.job.getByLocation'>['jobs'] | undefined
  selectedLocationSlug: string
}

export const DeletedJobs: FC<DeletedJobsProps> = ({
  jobs,
  selectedLocationSlug,
}) => {
  const [tableRowIdPopover, setTableRowIdPopover] = useState<string>()
  const {
    isOpen: isTableDropdownOpen,
    setIsOpen: setIsTableDropdownOpen,
    popoverPosition,
    handleReferenceElementClick,
  } = useTableDropdown()

  const [restoringJob, setRestoringJob] = useState<{ id: string }>()

  return (
    <Fragment>
      <DeletedJobsTable
        jobs={jobs}
        selectedLocationSlug={selectedLocationSlug}
        handleReferenceElementClick={handleReferenceElementClick}
        setTableRowIdPopover={setTableRowIdPopover}
      />
      {tableRowIdPopover && isTableDropdownOpen ? (
        <DeletedJobTableDropdown
          jobId={tableRowIdPopover}
          isOpen={isTableDropdownOpen}
          setIsOpen={setIsTableDropdownOpen}
          top={popoverPosition.top + 15}
          left={popoverPosition.left - 170}
          onRestoreJob={(jobId: string) => {
            setRestoringJob({ id: jobId })
          }}
        />
      ) : null}
      {restoringJob ? (
        <RestoreJobModal
          jobId={restoringJob.id}
          isOpen={Boolean(restoringJob)}
          setIsOpen={() => {
            setRestoringJob(undefined)
          }}
        />
      ) : null}
    </Fragment>
  )
}
