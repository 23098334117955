import { useMemberRole } from '@/hooks/useMemberRole'
import { InferQueryOutput } from '@/utils/trpc'
import { DotsHorizontalIcon } from '@heroicons/react/outline'
import { AtsJobStatus } from '@prisma/client'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'

import { toDisplayJobStatus } from '@/utils/toDisplayJobStatus'
import { hasUserObjectAccess } from '@people/common/utils/permissions'
import { Badge } from '@people/ui/badges'
import { Button } from '@people/ui/buttons'
import { Card } from '@people/ui/cards'
import { Table, TableHeader } from '@people/ui/table'

interface DeletedJobsTableProps {
  jobs: InferQueryOutput<'ats.job.getByLocation'>['jobs'] | undefined
  selectedLocationSlug: string | undefined
  handleReferenceElementClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  setTableRowIdPopover: (id: string) => void
}

export const DeletedJobsTable: FC<DeletedJobsTableProps> = ({
  jobs,
  selectedLocationSlug,
  handleReferenceElementClick,
  setTableRowIdPopover,
}) => {
  const { t } = useTranslation('common')

  const { query } = useRouter()
  // need to use useState here to prevent issue from parent component
  const [sorting, setSorting] = useState<SortingState>([])

  const orgSlug = query.orgSlug as string

  const { memberRole } = useMemberRole(orgSlug)

  const hasJobCreateAccess =
    memberRole &&
    hasUserObjectAccess(memberRole, {
      type: 'AtsJob',
      action: 'create',
    })

  const jobsToSee = jobs

  const tableData = jobsToSee?.map(job => {
    return {
      id: job.id,
      slug: job.lousySlug,
      name: job.title,
      status: job.status,
      total: job.applicantCount,
      locationName: job.organisationLocation.name,
      lousyLocationSlug: job.organisationLocation.lousyLocationSlug,
    }
  })

  const columnHelper = createColumnHelper<{
    id: string
    slug: string
    name: string
    status: string
    total: number
    locationName: string
    lousyLocationSlug: string
    actions?: any
  }>()

  const viewAll = selectedLocationSlug === 'all-jobs'

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>{t('job')}</span>,
      cell: info => (
        <span className="capitalize whitespace-nowrap sm:whitespace-normal sm:line-clamp-1 text-slate-900">
          {info.getValue()}
        </span>
      ),
      size: 600,
    }),
    columnHelper.accessor('total', {
      header: () => <span>{t('total')}</span>,
      cell: info => <span className="font-normal">{info.getValue()}</span>,
    }),
    columnHelper.accessor('locationName', {
      header: () => <span>{t('location')}</span>,
      cell: info => <span className="font-normal">{info.getValue()}</span>,
    }),
    columnHelper.accessor('status', {
      header: () => <span>{t('status')}</span>,
      cell: info => (
        <Badge
          className="capitalize"
          variant={
            info.getValue() === 'LIVE'
              ? 'success'
              : info.getValue() === 'CLOSED'
              ? 'warning'
              : info.getValue() === 'ARCHIVED'
              ? 'error'
              : 'info'
          }
        >
          <span className="font-medium">
            {toDisplayJobStatus(info.getValue() as AtsJobStatus, t)}
          </span>
        </Badge>
      ),
    }),
    columnHelper.accessor('actions', {
      header: () => <span></span>,
      enableSorting: false,
      size: 0,
      cell: info =>
        hasJobCreateAccess ? (
          <Button
            className="!border-none !shadow-none"
            variant="secondary"
            tabIndex={-1}
            onClick={e => {
              setTableRowIdPopover(info.row.original.id)
              handleReferenceElementClick(e)
            }}
          >
            <DotsHorizontalIcon className="h-[22px] w-[22px] text-blue-600" />
          </Button>
        ) : null,
    }),
  ]

  const table = useReactTable({
    data: tableData || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility: {
        location: viewAll,
      },
    },
    onSortingChange: setSorting,
  })

  return (
    <div>
      <Card className="overflow-auto pb-1 sm:!rounded-t-none">
        {tableData?.length === 0 ? (
          <div className="flex flex-col border-t border-slate-300 items-center gap-8 p-[100px] ">
            <img src="/images/empty-trash.svg" className="h-20 w-20" />
            <div className="flex w-full max-w-[210px] flex-col gap-2.5 text-center text-base text-slate-600">
              <p className="font-semibold text-slate-900">
                {t('noDeletedJobsTitle')}
              </p>

              <p className="text-slate-500 mt-1 max-w-[210px]">
                {t('noDeletedJobsDescription')}
              </p>
            </div>
          </div>
        ) : (
          <>
            <Table
              head={
                <>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableHeader key={headerGroup.id} header={headerGroup} />
                  ))}
                </>
              }
              body={table.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  className="h-16 border-t border-slate-200 hover:bg-slate-50 transition-colors"
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="px-2 text-left text-sm font-medium text-slate-600 first:pl-[18px] last:pr-[18px]"
                    >
                      {cell.column.id !== 'actions' ? (
                        <Link
                          href={`/${orgSlug}/${row.original.lousyLocationSlug}/${row.original.slug}`}
                        >
                          <a>
                            <div className="w-full py-4">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </div>
                          </a>
                        </Link>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            />
          </>
        )}
      </Card>
    </div>
  )
}
