import IconProps from './IconProps'

export const RedoIcon: React.FC<IconProps> = ({ className }): JSX.Element => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666656 8C0.666656 4.3181 3.65142 1.33334 7.33332 1.33334C9.17492 1.33334 10.8432 2.08093 12.049 3.28759C12.4754 3.71431 12.9321 4.24165 13.3333 4.72963V2.66667C13.3333 2.29848 13.6318 2 14 2C14.3682 2 14.6667 2.29848 14.6667 2.66667V6.66667C14.6667 7.03486 14.3682 7.33334 14 7.33334H9.99999C9.6318 7.33334 9.33332 7.03486 9.33332 6.66667C9.33332 6.29848 9.6318 6 9.99999 6H12.6461C12.1846 5.42086 11.611 4.73557 11.1059 4.23007C10.1399 3.26343 8.80693 2.66667 7.33332 2.66667C4.3878 2.66667 1.99999 5.05448 1.99999 8C1.99999 10.9455 4.3878 13.3333 7.33332 13.3333C9.76381 13.3333 11.8162 11.707 12.4583 9.48184C12.5604 9.12808 12.9299 8.92406 13.2836 9.02614C13.6374 9.12822 13.8414 9.49775 13.7393 9.8515C12.937 12.6321 10.3736 14.6667 7.33332 14.6667C3.65142 14.6667 0.666656 11.6819 0.666656 8Z"
        fill="#2563EB"
      />
    </svg>
  )
}

export default RedoIcon
