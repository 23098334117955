import { FC } from 'react'

import { Button } from '../buttons'
import { SimpleModal } from './'

export interface ConfirmationModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  title: string
  CTA: string
  onConfirm: () => void
  cancel?: string
  type?: 'info' | 'danger'
  isCTADisabled?: boolean
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  CTA,
  onConfirm,
  cancel = 'Cancel',
  children,
  type = 'info',
  isCTADisabled,
}) => {
  return (
    <SimpleModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="min-w-[440px]"
    >
      <div className="space-y-3">
        <h3 className="font-semibold text-slate-900 max-w-[90%]">{title}</h3>
        <div className="text-slate-700">{children}</div>
      </div>

      <div className="mt-4">
        <div className="grid grid-cols-2 gap-3">
          <Button
            variant="secondary"
            className="w-full"
            onClick={() => setIsOpen(false)}
            type="button"
          >
            {cancel}
          </Button>
          <Button
            disabled={isCTADisabled}
            variant={type === 'danger' ? 'warning' : 'primary'}
            className="w-full"
            onClick={() => {
              onConfirm()
              setIsOpen(false)
            }}
          >
            {CTA}
          </Button>
        </div>
      </div>
    </SimpleModal>
  )
}

export default ConfirmationModal
