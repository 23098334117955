import { DeletedJobs } from '@/components/job/DeletedJobs'
import { DuplicateJobModal } from '@/components/job/DuplicateJobModal'
import { JobTable } from '@/components/job/JobTable'
import { JobsLoader } from '@/components/loaders'
import { DeleteLocationModal } from '@/components/location/DeleteLocationModal'
import { EditLocationModal } from '@/components/location/EditLocationModal'
import { LocationData, LocationList } from '@/components/location/LocationList'
import { NewLocationModal } from '@/components/location/NewLocationModal'
import { AccessDenied } from '@/components/shared/AccessDenied'
import { DisabledWebhookWarnings } from '@/components/webhook/DisabledWebhookWarnings'
import { useUrlSorting } from '@/hooks/useUrlSorting'
import { trpc } from '@/utils/trpc'
import { Layout } from '@components/Layout'
import { LocationHeader } from '@components/location/LocationHeader'
import type { OrganisationLocation } from '@prisma/client'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

export const Jobs = () => {
  const { t } = useTranslation('common')

  const { query, push, replace } = useRouter()
  const orgSlug = query.orgSlug as string

  const [isAccessDenied, setIsAccessDenied] = useState<boolean>(false)
  const [isEditLocationModalOpen, setIsEditLocationModalOpen] = useState(false)
  const [isNewLocationModalOpen, setIsNewLocationModalOpen] = useState(false)

  const [isDeleteLocationModalOpen, setIsDeleteLocationModalOpen] =
    useState(false)

  const utils = trpc.useContext()
  const [jobTotalPages, setJobTotalPages] = useState<number>()
  const {
    data: locationsData,
    isLoading: isLocationDataLoading,
    isFetched: isLocationsFetched,
  } = trpc.useQuery(
    [
      'organisationLocation.getByOrgSlug',
      {
        orgSlug,
      },
    ],
    {
      enabled: Boolean(orgSlug),
    },
  )

  const activePage = query?.page ? Number(query.page) : 1
  const duplicateJobId = query?.duplicateJobId as string | undefined

  const locations = useMemo((): LocationData[] => {
    return (
      locationsData?.map((location): LocationData => {
        return {
          ...location,
          jobCount: location?.jobs?.length || 0,
          country: {
            name: location.country?.name || location.countryCode,
          },
        }
      }) || []
    )
  }, [locationsData])

  const selectedLocationSlug = (query.locationSlug as string | undefined)
    ? (query.locationSlug as string | undefined)
    : orgSlug === 'burgermeister'
    ? 'general-applications'
    : 'all-jobs'

  const selectedLocation = locations?.find(
    loc => loc.lousyLocationSlug === selectedLocationSlug,
  )

  const { sorting, setSorting } = useUrlSorting({
    defaultSorting: [
      {
        id: 'waiting',
        desc: true,
      },
    ],
  })

  const showLocationAllJobs = query.view === 'all'
  const showDeletedJobs = selectedLocationSlug === 'deleted-jobs'

  const { data: locationJobs, isLoading: isLoadingLocationJobs } =
    trpc.useQuery(
      [
        'ats.job.getByLocation',
        {
          lousyLocationSlug: selectedLocationSlug || '',
          orgSlug,
          page: activePage,
          view: showDeletedJobs
            ? 'deleted'
            : showLocationAllJobs
            ? 'all'
            : 'live',
          sortBy: sorting[0]
            ? ({
                field: sorting[0].id,
                order: sorting[0].desc ? 'desc' : 'asc',
              } as any)
            : {
                field: 'waiting',
                order: 'desc',
              },
        },
      ],
      {
        enabled: Boolean(orgSlug && selectedLocationSlug),
      },
    )

  const newLocationCallback = (newLocation: OrganisationLocation) => {
    setIsNewLocationModalOpen(false)
    utils.invalidateQueries(['organisationLocation.getByOrgSlug'])
    push(`/${orgSlug}/${newLocation.lousyLocationSlug}`)
  }

  useEffect(() => {
    if (!isAccessDenied) {
      if (isLocationsFetched && locationsData?.length === 0) {
        setIsAccessDenied(true)
      }
    }
  }, [locationsData, isLocationsFetched])

  useEffect(() => {
    if (locationJobs && Number(locationJobs.totalPages)) {
      setJobTotalPages(locationJobs.totalPages)
    }
  }, [locationJobs?.totalPages])

  const viewAll = selectedLocationSlug === 'all-jobs'
  const viewGeneralApplications =
    selectedLocationSlug === 'general-applications'

  const title =
    selectedLocationSlug === 'all-jobs'
      ? `Jobs in all locations`
      : selectedLocationSlug === 'deleted-jobs'
      ? t('deletedJobs')
      : selectedLocationSlug === 'general-applications'
      ? t('generalApplications')
      : `Jobs in ${selectedLocation?.name}`

  return (
    <Layout
      meta={{
        title: title,
      }}
    >
      {!isAccessDenied ? (
        <div className="mx-auto flex w-full max-w-[1400px] flex-col justify-center gap-8 py-5 sm:px-5 lg:flex-row">
          <div className="w-full lg:max-w-[280px] lg:min-w-[280px] ">
            <LocationList
              isLocationDataLoading={isLocationDataLoading}
              translations={t}
              locations={locations}
              selectedLocationSlug={selectedLocationSlug}
              setNewLocationModalOpen={setIsNewLocationModalOpen}
            />
          </div>
          <div className="w-full max-w-[1300px]">
            {(viewAll ||
              viewGeneralApplications ||
              (selectedLocationSlug && selectedLocation)) &&
            !isLoadingLocationJobs ? (
              <div className="space-y-5">
                <DisabledWebhookWarnings orgSlug={orgSlug} getBy={'org'} />

                <LocationHeader
                  name={(() => {
                    if (viewAll) return t('allLocations')
                    if (viewGeneralApplications) return t('generalApplications')
                    return selectedLocation?.name || ''
                  })()}
                  city={selectedLocation?.city}
                  streetAddress={selectedLocation?.streetAddress}
                  postalCode={selectedLocation?.postalCode}
                  country={selectedLocation?.country?.name}
                  translations={t}
                  selectedLocationSlug={selectedLocationSlug}
                  setIsEditLocationModalOpen={setIsEditLocationModalOpen}
                  setIsDeleteLocationModalOpen={setIsDeleteLocationModalOpen}
                />
              </div>
            ) : null}

            {(isLoadingLocationJobs || !selectedLocationSlug) && <JobsLoader />}

            {(viewAll || selectedLocation || viewGeneralApplications) &&
            !isLoadingLocationJobs ? (
              <div className="mt-5">
                <JobTable
                  sorting={sorting}
                  setSorting={setSorting}
                  jobs={locationJobs?.jobs}
                  selectedLocationSlug={selectedLocationSlug}
                  activePage={activePage}
                  setActivePage={p => {
                    push(
                      {
                        query: {
                          ...query,
                          page: p,
                        },
                      },
                      undefined,
                      { shallow: true },
                    )
                  }}
                  jobTotalPages={jobTotalPages || 1}
                  liveJobsCount={locationJobs?.liveJobCount || 0}
                  allJobsCount={locationJobs?.allJobCount || 0}
                />
              </div>
            ) : selectedLocationSlug === 'deleted-jobs' &&
              !isLoadingLocationJobs ? (
              <DeletedJobs
                jobs={locationJobs?.jobs}
                selectedLocationSlug={selectedLocationSlug}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <AccessDenied
          title="You don’t have access to jobs"
          description="Your account was restricted from accessing certain jobs. Contact your
        organisation’s admin to grant you access."
        />
      )}
      {isNewLocationModalOpen && (
        <NewLocationModal
          orgSlug={orgSlug}
          isOpen={isNewLocationModalOpen}
          setIsOpen={open => setIsNewLocationModalOpen(open)}
          newLocationCallback={newLocationCallback}
        />
      )}
      {duplicateJobId && (
        <DuplicateJobModal
          isOpen={Boolean(duplicateJobId)}
          jobId={duplicateJobId}
          setIsOpen={(val: boolean) => {
            if (!val) {
              replace({
                query: {
                  ...query,
                  duplicateJobId: undefined,
                },
              })
            }
          }}
        />
      )}
      {selectedLocation && isEditLocationModalOpen && (
        <EditLocationModal
          key={selectedLocation.id}
          location={{
            ...selectedLocation,
            longitude: selectedLocation.longitude || 0,
            latitude: selectedLocation.latitude || 0,
            city: selectedLocation.city || '',
            countryCode: selectedLocation.countryCode || '',
          }}
          isOpen={isEditLocationModalOpen}
          setIsOpen={open => setIsEditLocationModalOpen(open)}
        />
      )}
      {selectedLocation && isDeleteLocationModalOpen && (
        <DeleteLocationModal
          locationId={selectedLocation?.id}
          isOpen={isDeleteLocationModalOpen}
          setIsOpen={setIsDeleteLocationModalOpen}
        />
      )}
    </Layout>
  )
}

export default Jobs
