import { trpc } from '@/utils/trpc'
import { useForm } from '@mantine/form'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import { Button } from '@people/ui/buttons'
import { HeaderModal } from '@people/ui/modals'
import { ModalShellProps } from '@people/ui/modals/ModalShell'
import { ButtonSpinner } from '@people/ui/spinners'

import {
  LocationAddressForm,
  LocationAddressFormType,
  SingleLocation,
} from '../settings/location/LocationAddressForm'

interface EditLocationModalProps extends ModalShellProps {
  location: SingleLocation
}

export const EditLocationModal: FC<EditLocationModalProps> = ({
  isOpen,
  setIsOpen,
  location,
}) => {
  const { t } = useTranslation('common')

  const form = useForm<LocationAddressFormType>({
    initialValues: {
      locations: [location],
    },
  })

  const utils = trpc.useContext()

  const updateLocation = trpc.useMutation('organisationLocation.update', {
    onSuccess: () => {
      setIsOpen(false)
      form.reset()
      utils.invalidateQueries(['organisationLocation.getByOrgSlug'])
    },
    onError: error => {},
  })

  const handleLocationSubmit = async () => {
    if (form.isDirty()) {
      updateLocation.mutate({
        id: location.id,
        name: form.values.locations[0].name,
        streetAddress: form.values.locations[0].streetAddress,
        city: form.values.locations[0].city,
        postalCode: form.values.locations[0].postalCode,
        countryCode: form.values.locations[0].countryCode,
      })
    } else {
      setIsOpen(false)
      form.reset()
    }
  }

  return (
    <HeaderModal
      header={t('editLocationModalTitle')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="!max-w-[480px] !p-0"
    >
      <form className="text-left">
        <LocationAddressForm
          disabled={false}
          form={form}
          idx={0}
          clearLabels
          className="pb-6"
          isIniatialDataLoading={false}
          classNameInputs="!flex flex-col"
        />
        <div className="flex gap-[14px] border-t border-slate-200 p-5">
          <Button
            variant="secondary"
            onClick={() => {
              form.reset()
              setIsOpen(false)
            }}
            className="flex-1"
            type="button"
          >
            {t('cancel')}
          </Button>

          <Button
            className="flex-1 disabled:text-slate-300"
            disabled={updateLocation.isLoading}
            variant="primary"
            type="submit"
            onClick={() => handleLocationSubmit()}
          >
            {updateLocation.isLoading && (
              <ButtonSpinner className="-ml-0.5 mr-2 h-5 w-5 animate-spin " />
            )}
            {t('save')}
          </Button>
        </div>
      </form>
    </HeaderModal>
  )
}
