import { MapIcon } from '@heroicons/react/outline'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'
import { FormInput } from '../form'

export interface GooglePlaceField {
  className?: string
  setPlace: (place: google.maps.places.PlaceResult | undefined) => void
  initialValue?: string
  optional?: boolean
  label?: string
  placeholder?: string
}

export const GooglePlaceField: FC<GooglePlaceField> = ({
  className,
  setPlace,
  initialValue,
  optional,
  label,
  placeholder,
  ...other
}) => {
  const placeInputRef = useRef<HTMLInputElement>(null)
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null)

  const handlePlaceChange = () => {
    const place = autoCompleteRef.current?.getPlace()
    setPlace(place)
  }

  const isBrowser = typeof window !== 'undefined'

  useEffect(() => {
    function bindGoogleAutocomplete() {
      try {
        if (placeInputRef.current) {
          autoCompleteRef.current = new google.maps.places.Autocomplete(
            placeInputRef.current,
          )
        }
        autoCompleteRef.current?.addListener('place_changed', handlePlaceChange)
        clearInterval(binder)
      } catch (_e) {
        console.log('Google Maps API not loaded yet')
      }
    }

    const binder = setInterval(() => {
      bindGoogleAutocomplete()
    }, 1000)

    return () => {
      clearInterval(binder)
      if (autoCompleteRef.current) {
        google.maps.event.clearInstanceListeners(autoCompleteRef.current)
      }
    }
  }, [])

  return (
    <FormInput
      id="googlePlaceAddress"
      label={label || 'Address'}
      type="text"
      defaultValue={initialValue}
      placeholder={placeholder || 'Enter other address here if needed'}
      clearLabel
      required={!optional}
      ref={placeInputRef}
      onKeyPress={e => {
        e.key === 'Enter' && e.preventDefault()
      }}
      {...other}
    >
      <MapIcon className="h-5 w-5 text-slate-400" />
    </FormInput>
  )
}
