import { LocationsLoader } from '@/components/loaders'
import { InferQueryOutput, trpc } from '@/utils/trpc'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  GlobeIcon,
  LocationMarkerIcon,
  PlusCircleIcon,
  StatusOnlineIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import { useLocalStorage } from '@mantine/hooks'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import { useMemo } from 'react'

import { useMemberRole } from '@/hooks/useMemberRole'
import { isUserRoleAuthorized } from '@people/common/utils/permissions'
import { Card } from '@people/ui/cards'
import { LocationListItem } from '@people/ui/locations'
import { classNames } from '@people/ui/utils'

export type LocationData = {
  country?: {
    name: string
  }
  jobCount: number
} & InferQueryOutput<'organisationLocation.getByOrgSlug'>[0]
export interface LocationListProps {
  className?: string
  isLocationDataLoading: boolean
  translations: (key: string) => string
  locations: LocationData[]
  selectedLocationSlug: string | undefined
  setNewLocationModalOpen: (isOpen: boolean) => void
}

export const LocationList: FC<LocationListProps> = ({
  className,
  isLocationDataLoading,
  translations: t,
  locations,
  selectedLocationSlug,
  setNewLocationModalOpen,
}) => {
  const { query } = useRouter()

  const orgSlug = query.orgSlug as string
  const view = query.view as string

  const { memberRole } = useMemberRole(orgSlug)

  const [locationListMenuState, setLocationListMenuState] = useLocalStorage<
    'collapsed' | 'expanded'
  >({
    key: 'locationListMenuState',
    defaultValue: 'expanded',
  })

  const { data: deletedJobCount, isLoading: isLoadingOrgDeletedJobCount } =
    trpc.useQuery([
      'ats.job.getDeletedJobCountByOrgSlug',
      {
        orgSlug: query.orgSlug as string,
      },
    ])

  const allJobCount = useMemo(() => {
    return (
      locations?.reduce(function (acc, location) {
        return acc + location?.jobCount || 0
      }, 0) || 0
    )
  }, [locations])

  if (isLocationDataLoading) {
    return <LocationsLoader />
  }

  const viewAll = selectedLocationSlug === 'all-jobs'
  const viewDeleted = selectedLocationSlug === 'deleted-jobs'
  const viewGeneralApplications =
    selectedLocationSlug === 'general-applications'
  const numberOfJobTypesAcceptingApplications = locations.find(
    l => l.isTalentPool,
  )?.jobCount

  const showLocations = locationListMenuState === 'expanded'

  return (
    <Card
      className={classNames(
        'flex flex-col overflow-hidden py-2',
        className ?? '',
      )}
    >
      <div className="sm:flex flex-col gap-1.5 font-medium text-slate-900">
        {(orgSlug === 'burgermeister' || orgSlug === 'teero-dental') &&
          isUserRoleAuthorized({
            memberRole,
            minRequiredRole: 'ADMIN',
          }) && (
            <Link href={`/${orgSlug}/general-applications`}>
              <a className="flex flex-row items-center hover:bg-blue-50 mx-2.5 px-2.5 py-1.5 rounded-md">
                <GlobeIcon className="h-[18px] w-[18px] text-blue-600" />
                <p
                  className={classNames(
                    'ml-2.5',
                    viewGeneralApplications ? 'text-blue-600' : '',
                  )}
                >
                  {t('generalApplications')}
                </p>
                <p
                  className={classNames(
                    'ml-auto text-sm',
                    viewGeneralApplications
                      ? 'font-medium text-blue-600'
                      : 'text-slate-400 ',
                  )}
                >
                  {numberOfJobTypesAcceptingApplications}
                </p>
              </a>
            </Link>
          )}
        <Link href={`/${orgSlug}/all-jobs`}>
          <a className="flex flex-row items-center hover:bg-blue-50 mx-2.5 px-2.5 py-1.5 rounded-md">
            <StatusOnlineIcon className="h-[18px] w-[18px] text-blue-600" />
            <p className={classNames('ml-2.5', viewAll ? 'text-blue-600' : '')}>
              {t('activeJobs')}
            </p>
            <p
              className={classNames(
                'ml-auto text-sm',
                viewAll ? 'font-medium text-blue-600' : 'text-slate-400 ',
              )}
            >
              {allJobCount}
            </p>
          </a>
        </Link>

        <div
          className="flex flex-row items-center hover:bg-blue-50 cursor-pointer mx-2.5 px-2.5 py-1.5 rounded-md"
          onClick={() => {
            setLocationListMenuState(
              locationListMenuState === 'collapsed' ? 'expanded' : 'collapsed',
            )
          }}
        >
          <LocationMarkerIcon className="h-[18px] w-[18px] text-blue-600" />
          <p
            className={classNames(
              'ml-2.5',
              viewAll || viewDeleted ? '' : 'text-blue-600',
            )}
          >
            {t('allLocations')}
          </p>
          {showLocations ? (
            <ChevronUpIcon className="ml-auto h-4 w-4 text-blue-600" />
          ) : (
            <ChevronDownIcon className="ml-auto h-4 w-4 text-blue-600" />
          )}
        </div>

        {showLocations ? (
          <div>
            <div className="flex flex-col">
              {locations
                ?.filter(l => !l.isTalentPool)
                .map(location => (
                  <Link
                    key={location.id}
                    href={`/${orgSlug}/${
                      location.lousyLocationSlug
                        ? location.lousyLocationSlug
                        : ''
                    }${view ? `?view=${view}` : ''}`}
                  >
                    <a>
                      <LocationListItem
                        className="mx-2.5 pl-3.5 whitespace-nowrap items-center"
                        name={location.name}
                        jobCount={location.jobCount}
                        active={
                          selectedLocationSlug === location.lousyLocationSlug
                        }
                      />
                    </a>
                  </Link>
                ))}
            </div>
          </div>
        ) : null}

        {showLocations &&
        isUserRoleAuthorized({
          memberRole,
          minRequiredRole: 'ADMIN',
        }) ? (
          <div
            className="flex flex-row items-center text-blue-600 hover:bg-blue-50 mx-2.5 px-2.5 py-1.5 rounded-md cursor-pointer"
            onClick={() => setNewLocationModalOpen(true)}
          >
            <PlusCircleIcon className="h-[18px] w-[18px]" aria-hidden="true" />
            <span className="flex-1 text-left ml-2.5">
              {t('createNewLocation')}
            </span>
          </div>
        ) : null}

        {isUserRoleAuthorized({
          memberRole,
          minRequiredRole: 'MANAGER',
        }) ? (
          <>
            <div className="h-[1px] w-full bg-slate-200" />
            <Link href={`/${orgSlug}/deleted-jobs`}>
              <a className="flex flex-row items-center hover:bg-blue-50 mx-2.5 px-2.5 py-1.5 rounded-md">
                <TrashIcon
                  className="h-[18px] w-[18px] text-blue-600"
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    'ml-2.5',
                    viewDeleted ? 'text-blue-600' : '',
                  )}
                >
                  {t('deleted')}
                </span>

                <p
                  className={classNames(
                    'ml-auto text-sm',
                    viewDeleted
                      ? 'font-medium text-blue-600'
                      : 'text-slate-400 ',
                  )}
                >
                  {deletedJobCount || 0}
                </p>
              </a>
            </Link>
          </>
        ) : null}
      </div>
    </Card>
  )
}
