import { trpc } from '@/utils/trpc'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'

import { FormSelect } from '@people/ui/form'
import { useNotification } from '@people/ui/hooks/useNotification'
import { ConfirmationModal } from '@people/ui/modals'
import { ModalShellProps } from '@people/ui/modals/ModalShell'

interface RestoreJobModalProps extends ModalShellProps {
  jobId: string
}

export const RestoreJobModal: FC<RestoreJobModalProps> = ({
  isOpen,
  setIsOpen,
  jobId,
}) => {
  const { t } = useTranslation('common')

  const [selectedLocationId, setSelectedLocationId] = useState<string>()

  const { showNotification } = useNotification()

  const { query, push } = useRouter()
  const orgSlug = query.orgSlug as string

  const utils = trpc.useContext()

  const { data: locations, isLoading: isLoadingLocations } = trpc.useQuery(
    [
      'organisationLocation.getByOrgSlug',
      {
        orgSlug,
      },
    ],
    {
      enabled: Boolean(orgSlug),
    },
  )

  const { mutate: restoreJob } = trpc.useMutation('ats.job.restoreJob', {
    onSuccess: data => {
      showNotification({
        title: t('jobRestored'),
        type: 'success',
      })

      utils.invalidateQueries(['ats.job.getByLocation'])
      utils.invalidateQueries(['organisationLocation.getByOrgSlug'])
    },
    onError: err => {
      showNotification({
        title: t('error'),
        message: err.message,
        type: 'error',
      })
    },
  })

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={t('restoreJobModalTitle')}
      setIsOpen={open => setIsOpen(open)}
      isCTADisabled={!Boolean(selectedLocationId)}
      onConfirm={() => {
        if (!selectedLocationId) {
          return
        }

        restoreJob({
          jobId,
          locationId: selectedLocationId,
        })
      }}
      CTA={t('restoreJobModalCTA')}
      cancel={t('cancel')}
      type="info"
    >
      {t('restoreJobModalDescription')}

      <FormSelect
        className="mt-5 capitalize"
        onChange={e => {
          setSelectedLocationId(e.target.value)
        }}
        iconClassName={'text-blue-600'}
      >
        <option disabled hidden selected value={''}>
          {t('location')}
        </option>
        {locations?.map((l, idx) => {
          return (
            <option value={l.id} key={idx}>
              {l.name.toLocaleLowerCase()}
            </option>
          )
        })}
      </FormSelect>

      <div className="mt-5 mb-5 h-[1px] w-full bg-slate-200" />
    </ConfirmationModal>
  )
}
