import { useAuth } from '@/hooks/useAuth'
import { classNames } from '@/utils/classNames'
import { TrashIcon } from '@heroicons/react/outline'
import { OrganisationRole } from '@prisma/client'
import { useTranslation } from 'next-i18next'

interface LocationMemberProps {
  name: string
  email: string
  image?: string
  role: OrganisationRole
  onSelect?: (member: { name: string; email: string }) => void
  onRemove?: (member: { name: string; email: string }) => void
}

export const LocationMember: React.FC<LocationMemberProps> = ({
  name,
  email,
  image,
  role,
  onSelect,
  onRemove,
}: LocationMemberProps) => {
  const { user } = useAuth()
  const { t } = useTranslation('common')

  return (
    <div
      className="flex flex-row px-2.5 py-1.5 text-start gap-[14px] items-center sm:min-w-[360px] hover:bg-blue-50"
      onClick={() => {
        onSelect?.({
          name,
          email,
        })
      }}
    >
      <div className="h-8 w-8 rounded-full bg-slate-200 overflow-hidden items-center flex justify-center">
        {image ? (
          <img src={image} alt="" className="inline" />
        ) : (
          <div className="text-xs text-slate-600 cursor-default">
            {name
              ?.split(' ')
              .map(partName => partName.slice(0, 1))
              .join('')}
          </div>
        )}
      </div>
      <div className="whitespace-nowrap flex-col">
        <div className="text-sm font-medium text-gray-900 ">
          {name || ''} {user?.email === email ? `(${t('you')})` : null}
        </div>
        <div className="whitespace-nowrap text-xs text-gray-500 font-normal">
          {email}
        </div>
      </div>

      <p className="ml-auto text-slate-600 text-xs capitalize font-normal">
        {role.toLowerCase()}
      </p>

      {onRemove ? (
        <button
          className={classNames(
            user?.email !== email ? 'border-l' : '',
            'flex min-w-[40px] items-center text-red-500 hover:text-red-600  p-1 max-h-6',
          )}
          onClick={() => {
            if (user?.email !== email) {
              onRemove({ email, name })
            }
          }}
          type="button"
        >
          {user?.email !== email ? (
            <div className="hover:bg-red-100 p-1 rounded-md">
              <TrashIcon className=" p-0.5 w-5 h-5 " />
            </div>
          ) : null}
        </button>
      ) : null}
    </div>
  )
}
