import { XIcon } from '@heroicons/react/outline'
import type { FC } from 'react'

import { classNames } from '../utils'
import ModalShell from './ModalShell'

export interface SimpleModalProps {
  className?: string
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

export const SimpleModal: FC<SimpleModalProps> = ({
  className,
  isOpen,
  setIsOpen,
  children,
}) => {
  return (
    <ModalShell
      {...{ isOpen, setIsOpen }}
      className={classNames(
        className ?? '',
        'p-5 text-left sm:my-8 sm:w-full sm:max-w-sm',
      )}
    >
      <div className="absolute top-0 right-0 hidden pt-[18px] pr-4 sm:block">
        <button
          type="button"
          className="rounded-md bg-white text-blue-600 hover:text-blue-500 focus:outline-none "
          onClick={() => setIsOpen(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      {children}
    </ModalShell>
  )
}

export default SimpleModal
