import { Pagination } from '@/components/pagination'
import { InferQueryOutput } from '@/utils/trpc'
import {
  BriefcaseIcon,
  DotsHorizontalIcon,
  StatusOnlineIcon,
} from '@heroicons/react/outline'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

import { useUrlSorting } from '@/hooks/useUrlSorting'
import { Button } from '@people/ui/buttons'
import { Card } from '@people/ui/cards'
import { DropdownPop } from '@people/ui/dropdown'
import { Table, TableHeader } from '@people/ui/table'
import { Tab } from '@people/ui/tabs'
import { classNames } from '@people/ui/utils'
import { AtsJobStatus } from '@prisma/client'
import { JobStatusBadge } from './JobStatusBadge'
import { JobTableDropdown } from './JobTableDropdown'

export interface JobTableProps {
  jobs: InferQueryOutput<'ats.job.getByLocation'>['jobs'] | undefined
  selectedLocationSlug: string | undefined

  activePage: number
  setActivePage: (p: number) => void
  jobTotalPages: number
  allJobsCount: number
  liveJobsCount: number

  sorting: ReturnType<typeof useUrlSorting>['sorting']
  setSorting: ReturnType<typeof useUrlSorting>['setSorting']
}

export const JobTable: FC<JobTableProps> = ({
  jobs,
  selectedLocationSlug,
  activePage,
  setActivePage,
  jobTotalPages,
  allJobsCount,
  liveJobsCount,

  sorting,
  setSorting,
}) => {
  const { t } = useTranslation('common')
  const { query, push } = useRouter()

  const orgSlug = query.orgSlug as string

  const tableData = jobs?.map(job => {
    return {
      id: job.id,
      slug: job.lousySlug,
      title: job.title,
      status: job.status,
      inProgress: job.inProgress,
      waiting: job.waitingApplicantCount,
      hired: job.hired,
      location: job.organisationLocation.name,
      lousyLocationSlug: job.organisationLocation.lousyLocationSlug,
      isTalentPool: job.organisationLocation.isTalentPool,
      moved: job.moved,
    }
  })

  const columnHelper = createColumnHelper<{
    id: string
    slug: string
    title: string
    status: string
    waiting?: number
    inProgress?: number
    hired?: number
    moved?: number
    location?: string
    lousyLocationSlug?: string
    actions?: any
    isTalentPool?: boolean
  }>()

  const viewAll = selectedLocationSlug === 'all-jobs'
  const viewGeneralApplications =
    selectedLocationSlug === 'general-applications'

  const columns = [
    columnHelper.accessor('title', {
      header: () => <span>{t('job')}</span>,
      cell: info => (
        <span className="capitalize whitespace-nowrap sm:whitespace-normal sm:line-clamp-1 text-slate-900  sm:min-w-[250px]">
          {info.getValue()}
        </span>
      ),
      size: 600,
    }),
    columnHelper.accessor('inProgress', {
      header: () => <span className="">{t('inProgress')}</span>,
      cell: info => <span className="font-normal">{info.getValue()}</span>,
    }),
    columnHelper.accessor('waiting', {
      header: () => <span>{t('waiting')}</span>,
      cell: info => <div className="font-normal">{info.getValue()}</div>,
    }),
    columnHelper.accessor('hired', {
      header: () => {
        return <span>{t('hired')}</span>
      },
      cell: info => (
        <span className="font-normal">
          {info.row.original.isTalentPool ? '-' : info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('moved', {
      header: () => {
        return <span>{t('moved')}</span>
      },
      cell: info => <span className="font-normal">{info.getValue()}</span>,
    }),
    columnHelper.accessor(row => row.location, {
      id: 'location',
      cell: info => (
        <i className="whitespace-nowrap font-normal not-italic">
          {info.row.original.isTalentPool
            ? t('generalApplications')
            : info.getValue()}
        </i>
      ),
      header: () => <span>{t('locationName')}</span>,
    }),
    columnHelper.accessor('status', {
      header: () => <span>{t('status')}</span>,
      cell: info => (
        <JobStatusBadge status={info.getValue() as AtsJobStatus} size={'xl'} />
      ),
    }),
    columnHelper.accessor('actions', {
      header: () => <span></span>,
      enableSorting: false,
      size: 0,
      cell: info => (
        <DropdownPop
          items={
            <JobTableDropdown
              job={jobs?.find(job => job.id === info.row.original.id)}
            />
          }
          button={
            <Button
              className="!border-none !shadow-none !bg-transparent"
              variant="secondary"
            >
              <DotsHorizontalIcon className="h-[22px] w-[22px] text-blue-600" />
            </Button>
          }
        />
      ),
    }),
  ]

  const table = useReactTable({
    data: tableData || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility: {
        location: viewAll,
        hired: !viewGeneralApplications,
        moved: viewGeneralApplications,
      },
    },
    enableMultiSort: false,
    manualSorting: true,
    onSortingChange: setSorting,
  })

  return (
    <div>
      <Card className="overflow-hidden sm:!rounded-b-none">
        <div className="flex flex-col sm:flex-row sm:h-14 bg-slate-200 gap-[1px]">
          <Tab
            current={query.view === undefined || query.view === 'active'}
            count={liveJobsCount}
            className="w-full cursor-pointer bg-white"
            onClick={() =>
              push(`/${orgSlug}/${selectedLocationSlug}/?view=active`)
            }
          >
            <div className="flex items-center whitespace-nowrap">
              <StatusOnlineIcon
                className={classNames(
                  'mr-2 h-[18px] w-[18px]',
                  query.view === undefined || query.view === 'active'
                    ? 'text-blue-600'
                    : 'text-slate-400',
                )}
              />
              {viewGeneralApplications
                ? t('activeGeneralApplications')
                : t('activeJobs')}
            </div>
          </Tab>

          <Tab
            current={query.view === 'all'}
            count={allJobsCount}
            className="w-full cursor-pointer bg-white"
            onClick={() =>
              push(`/${orgSlug}/${selectedLocationSlug}/?view=all`)
            }
          >
            <div className="flex items-center whitespace-nowrap">
              <BriefcaseIcon
                className={classNames(
                  'mr-2 h-[18px] w-[18px]',
                  query.view === 'all' ? 'text-blue-600' : 'text-slate-400',
                )}
              />
              {viewGeneralApplications
                ? t('archivedGeneralApplications')
                : t('allJobs')}
            </div>
          </Tab>
        </div>
      </Card>
      <Card className="overflow-auto pb-1 sm:!rounded-t-none">
        {tableData?.length === 0 ? (
          <div className="flex flex-col border-t border-slate-300 items-center gap-8 p-[100px] ">
            <img src="/images/no-jobs.svg" className="h-20 w-20" />
            <div className="flex w-full max-w-[280px] flex-col gap-2.5 text-center text-base text-slate-600">
              <div className="font-semibold text-slate-900">
                {query.view === 'ARCHIVED'
                  ? t('noArchivedJobs')
                  : t('createFirstJob')}
              </div>
              {t('createNewJobCta')}
            </div>
          </div>
        ) : (
          <>
            <Table
              head={
                <>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableHeader key={headerGroup.id} header={headerGroup} />
                  ))}
                </>
              }
              body={table.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  className="h-16 border-t border-slate-200 hover:bg-slate-50 transition-colors"
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="px-2 text-left text-sm font-medium text-slate-600 first:pl-[18px] last:pr-[18px]"
                    >
                      {cell.column.id !== 'actions' ? (
                        <Link
                          href={`/${orgSlug}/${
                            row.original.lousyLocationSlug
                          }/${row.original.slug}${
                            jobs &&
                            jobs?.length > 0 &&
                            row.original.isTalentPool
                              ? '?isTalentPool=true'
                              : ''
                          }`}
                        >
                          <a>
                            <div className="w-full py-4">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </div>
                          </a>
                        </Link>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            />
            {jobTotalPages > 1 && (
              <Pagination
                className="pt-4 pb-3 border-t"
                initialPage={1}
                activePage={activePage}
                onSetPage={p => {
                  setActivePage(p)
                }}
                totalPages={jobTotalPages || 1}
              />
            )}
          </>
        )}
      </Card>
    </div>
  )
}
