import { useMemberRole } from '@/hooks/useMemberRole'
import { InferQueryOutput, trpc } from '@/utils/trpc'
import { useDebouncedValue } from '@mantine/hooks'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, Fragment, useRef, useState } from 'react'

import { hasUserObjectAccess } from '@people/common/utils/permissions'
import { Button } from '@people/ui/buttons'
import { FormInput } from '@people/ui/form'
import { useNotification } from '@people/ui/hooks/useNotification'
import { ConfirmationModal, HeaderModal } from '@people/ui/modals'
import { ButtonSpinner } from '@people/ui/spinners'
import { classNames } from '@people/ui/utils'

import { LocationMember } from './LocationMember'
import { LocationMemberSearchResult } from './LocationMemberSearchResult'

interface LocationMembersModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  locationMembers: InferQueryOutput<'org.member.getMembersByLocation'>
}

export const LocationMemberModal: FC<LocationMembersModalProps> = ({
  isOpen,
  setIsOpen,
  locationMembers,
}) => {
  const { t } = useTranslation('common')
  const { query } = useRouter()
  const orgSlug = query.orgSlug as string
  const locationSlug = query.locationSlug as string

  const { showNotification } = useNotification()

  const { memberRole } = useMemberRole(orgSlug)

  const [removingMember, setRemovingMember] = useState<{
    name: string
    email: string
  }>()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200)

  const searchTermRef = useRef<HTMLInputElement>(null)

  const utils = trpc.useContext()

  const hasAddAccess =
    memberRole &&
    hasUserObjectAccess(memberRole, {
      type: 'UserOnOrgLocation',
      action: 'create',
    })
  const hasDeleteAccess =
    memberRole &&
    hasUserObjectAccess(memberRole, {
      type: 'UserOnOrgLocation',
      action: 'delete',
    })

  const {
    mutate: removeUserFromLocation,
    isLoading: isLoadingRemoveUserFromLocation,
  } = trpc.useMutation('org.userLocationAccess.removeUserFromLocation', {
    onSuccess: () => {
      showNotification({
        title: t('userRemovedFromLocation'),
        type: 'success',
        timeout: 5000,
      })
      Promise.all([
        utils.invalidateQueries(['org.member.getByOrg']),
        utils.invalidateQueries(['org.member.getMembersByLocation']),
      ])
    },
    onError: err => {
      showNotification({
        title: t('errorRemovingUserFromLocation'),
        message: err.message,
        type: 'error',
      })
    },
  })

  const {
    mutate: setUserInOrgLocation,
    isLoading: isLoadingSetUserInOrgLocation,
  } = trpc.useMutation('org.userLocationAccess.setUserInOrgLocation', {
    onSuccess: () => {
      showNotification({
        title: t('userAddedToThisLocation'),
        type: 'success',
        timeout: 5000,
      })
      Promise.all([
        utils.invalidateQueries(['org.member.getByOrg']),
        utils.invalidateQueries(['org.member.getMembersByLocation']),
      ])
    },
    onError: err => {
      showNotification({
        title: t('errorAddingUserToLocation'),
        message: err.message,
        type: 'error',
      })
    },
  })

  const [selectedMember, setSelectedMember] = useState<{
    name: string
    email: string
  }>()

  const { data: searchResults, isLoading: isLoadingSearchResults } =
    trpc.useQuery(
      [
        'org.member.search',
        {
          orgSlug: orgSlug,
          searchTerm: debouncedSearchTerm as string,
        },
      ],
      {
        enabled: Boolean(debouncedSearchTerm),
      },
    )

  return (
    <Fragment>
      <HeaderModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className={classNames('sm:w-[500px]', removingMember ? 'hidden' : '')}
        header={() => <>{t('locationMembers')}</>}
      >
        <div className="flex flex-col">
          {hasAddAccess ? (
            <div className="flex gap-2 flex-row pt-5 px-5 justify-between items-end pb-5">
              <div className="flex flex-col w-[80%] items-start relative">
                <label
                  htmlFor="members"
                  className="text-slate-900 mb-1 text-sm"
                >
                  {t('addMembersToLocation')}
                </label>
                <FormInput
                  ref={searchTermRef}
                  type="searchTerm"
                  id="searchTerm"
                  className="border w-full rounded border-slate-300 text-slate-900 placeholder:text-slate-400"
                  placeholder={t('nameOrEmail')}
                  onChange={e => {
                    setSelectedMember(undefined)

                    const val = e.currentTarget.value
                    setSearchTerm(val)
                  }}
                />

                {!selectedMember && searchTerm ? (
                  <LocationMemberSearchResult
                    isLoading={isLoadingSearchResults}
                    members={searchResults}
                    onSelectMember={member => {
                      setSelectedMember({
                        name: member.name,
                        email: member.email,
                      })

                      if (searchTermRef.current) {
                        searchTermRef.current.value = member.email
                      }
                    }}
                  />
                ) : null}
              </div>

              <Button
                className="h-[42px] w-20"
                disabled={!selectedMember}
                variant={'primary'}
                onClick={() => {
                  if (!isLoadingSetUserInOrgLocation && selectedMember) {
                    setUserInOrgLocation({
                      orgSlug,
                      locationSlug,
                      memberEmail: selectedMember.email,
                    })
                  }
                }}
              >
                {isLoadingSetUserInOrgLocation ? (
                  <ButtonSpinner className="-ml-0.5 mr-2 h-4 w-4 animate-spin " />
                ) : (
                  t('add')
                )}
              </Button>
            </div>
          ) : null}

          <div className="flex flex-col py-1 min-h-[290px] max-h-[290px] overflow-auto border-t border-slate-200">
            {locationMembers.map(lm => {
              return (
                <LocationMember
                  key={lm.email}
                  email={lm.email}
                  name={lm.name || ''}
                  role={lm.role}
                  image={lm.image as string}
                  onRemove={
                    hasDeleteAccess
                      ? member => {
                          setRemovingMember(member)
                        }
                      : undefined
                  }
                />
              )
            })}
          </div>
        </div>
      </HeaderModal>
      {removingMember ? (
        <ConfirmationModal
          isOpen={Boolean(removingMember)}
          CTA={'Yes, remove it'}
          type={'danger'}
          setIsOpen={() => {
            setRemovingMember(undefined)
          }}
          title={t('areYouSure')}
          onConfirm={() => {
            removeUserFromLocation({
              orgSlug,
              locationSlug,
              memberEmail: removingMember.email,
            })

            setRemovingMember(undefined)
          }}
        >
          {t('areYouSureYouWantToRemove')} {removingMember?.name}?{' '}
          {t('theyMayNotBeAbleToAccessLocation')}
        </ConfirmationModal>
      ) : null}
    </Fragment>
  )
}
